define("discourse/plugins/discourse-docs/discourse/components/docs-topic", ["exports", "@ember/component", "@ember/object/computed", "@ember-decorators/component", "discourse-common/lib/debounce", "discourse-common/utils/decorators", "@ember/template-factory"], function (_exports, _component, _computed, _component2, _debounce, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DButton
    @label="docs.topic.back"
    class="docs-nav-link return"
    @action={{this.return}}
  />
  
  <div class="topic-content">
    <h1>{{html-safe this.topic.fancyTitle}}</h1>
  
    {{! template-lint-disable no-capital-arguments }}
    <MountWidget @widget="post" @model={{this.model}} @args={{this.post}} />
  </div>
  
  <a class="docs-nav-link more" href="/t/{{this.topic.id}}">
    {{d-icon "far-comment"}}
    {{i18n "docs.topic.navigate_to_topic"}}
  </a>
  
  <span>
    <PluginOutlet @name="after-docs-topic" @connectorTagName="div" />
  </span>
  */
  {
    "id": "wy932nEX",
    "block": "[[[8,[39,0],[[24,0,\"docs-nav-link return\"]],[[\"@label\",\"@action\"],[\"docs.topic.back\",[30,0,[\"return\"]]]],null],[1,\"\\n\\n\"],[10,0],[14,0,\"topic-content\"],[12],[1,\"\\n  \"],[10,\"h1\"],[12],[1,[28,[35,1],[[30,0,[\"topic\",\"fancyTitle\"]]],null]],[13],[1,\"\\n\\n\"],[1,\"  \"],[8,[39,2],null,[[\"@widget\",\"@model\",\"@args\"],[\"post\",[30,0,[\"model\"]],[30,0,[\"post\"]]]],null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,3],[14,0,\"docs-nav-link more\"],[15,6,[29,[\"/t/\",[30,0,[\"topic\",\"id\"]]]]],[12],[1,\"\\n  \"],[1,[28,[35,3],[\"far-comment\"],null]],[1,\"\\n  \"],[1,[28,[35,4],[\"docs.topic.navigate_to_topic\"],null]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,1],[12],[1,\"\\n  \"],[8,[39,5],null,[[\"@name\",\"@connectorTagName\"],[\"after-docs-topic\",\"div\"]],null],[1,\"\\n\"],[13]],[],false,[\"d-button\",\"html-safe\",\"mount-widget\",\"d-icon\",\"i18n\",\"plugin-outlet\"]]",
    "moduleName": "discourse/plugins/discourse-docs/discourse/components/docs-topic.hbs",
    "isStrictMode": false
  });
  const DocsTopic = dt7948.c(class DocsTopic extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "originalPostContent", [(0, _computed.reads)("post.cooked")]))();
    #originalPostContent = (() => (dt7948.i(this, "originalPostContent"), void 0))();
    post(stream) {
      return this.store.createRecord("post", stream?.posts.firstObject);
    }
    static #_2 = (() => dt7948.n(this.prototype, "post", [(0, _decorators.default)("topic.post_stream")]))();
    model() {
      const post = this.post;
      if (!post.topic) {
        post.set("topic", this.topic);
      }
      return post;
    }
    static #_3 = (() => dt7948.n(this.prototype, "model", [(0, _decorators.default)("post", "topic")]))();
    _emitScrollEvent() {
      this.appEvents.trigger("docs-topic:current-post-scrolled");
    }
    static #_4 = (() => dt7948.n(this.prototype, "_emitScrollEvent", [_decorators.bind]))();
    debounceScrollEvent() {
      (0, _debounce.default)(this, this._emitScrollEvent, 200);
    }
    static #_5 = (() => dt7948.n(this.prototype, "debounceScrollEvent", [_decorators.bind]))();
    didInsertElement() {
      super.didInsertElement(...arguments);
      document.body.classList.add("archetype-docs-topic");
      document.addEventListener("scroll", this.debounceScrollEvent);
    }
    willDestroyElement() {
      super.willDestroyElement(...arguments);
      document.body.classList.remove("archetype-docs-topic");
      document.removeEventListener("scroll", this.debounceScrollEvent);
    }
  }, [(0, _component2.classNames)("docs-topic")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DocsTopic);
});