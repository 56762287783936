define("discourse/plugins/discourse-docs/discourse/components/docs-search", ["exports", "@ember/component", "@ember/object", "@ember-decorators/component", "@ember/template-factory"], function (_exports, _component, _object, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <span class="docs-search-wrapper">
    <Input
      @type="text"
      @value={{readonly this.searchTerm}}
      class="no-blur docs-search-bar"
      autocorrect="off"
      placeholder={{i18n "docs.search.placeholder"}}
      autocapitalize="off"
      {{on "keydown" this.onKeyDown}}
    />
  
    {{#if this.searchTerm}}
      <DButton
        @action={{action "clearSearch"}}
        class="clear-search"
        @label="docs.search.clear"
      />
    {{else}}
      {{d-icon "magnifying-glass"}}
    {{/if}}
  </span>
  */
  {
    "id": "QOGn/4/b",
    "block": "[[[10,1],[14,0,\"docs-search-wrapper\"],[12],[1,\"\\n  \"],[8,[39,0],[[24,0,\"no-blur docs-search-bar\"],[24,\"autocorrect\",\"off\"],[16,\"placeholder\",[28,[37,1],[\"docs.search.placeholder\"],null]],[24,\"autocapitalize\",\"off\"],[4,[38,3],[\"keydown\",[30,0,[\"onKeyDown\"]]],null]],[[\"@type\",\"@value\"],[\"text\",[28,[37,2],[[30,0,[\"searchTerm\"]]],null]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"searchTerm\"]],[[[1,\"    \"],[8,[39,5],[[24,0,\"clear-search\"]],[[\"@action\",\"@label\"],[[28,[37,6],[[30,0],\"clearSearch\"],null],\"docs.search.clear\"]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[28,[35,7],[\"magnifying-glass\"],null]],[1,\"\\n\"]],[]]],[13]],[],false,[\"input\",\"i18n\",\"readonly\",\"on\",\"if\",\"d-button\",\"action\",\"d-icon\"]]",
    "moduleName": "discourse/plugins/discourse-docs/discourse/components/docs-search.hbs",
    "isStrictMode": false
  });
  const DocsSearch = dt7948.c(class DocsSearch extends _component.default {
    onKeyDown(event) {
      if (event.key === "Enter") {
        this.set("searchTerm", event.target.value);
        this.onSearch(event.target.value);
      }
    }
    static #_ = (() => dt7948.n(this.prototype, "onKeyDown", [_object.action]))();
    clearSearch() {
      this.set("searchTerm", "");
      this.onSearch("");
    }
    static #_2 = (() => dt7948.n(this.prototype, "clearSearch", [_object.action]))();
  }, [(0, _component2.classNames)("docs-search")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DocsSearch);
});